import React from 'react';

function LandingPage({ onStartGame }) {
  return (
    <div className="landing-page">
      <h1>FeatureChain</h1>
      
      <section>
        <h2>Daily Challenge</h2>
        <button onClick={() => onStartGame('daily')}>Play</button>
      </section>
      
      <section>
        <h2>Freeplay</h2>
        <div className="button-group">
          <button onClick={() => onStartGame('normal')}>Normal</button>
          <button onClick={() => onStartGame('hard')}>Hard</button>
          <button onClick={() => onStartGame('expert')}>Expert</button>
        </div>
      </section>
    </div>
  );
}

export default LandingPage;