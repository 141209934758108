import React, { useState, useEffect } from 'react';

const clientId = '1235a29f6d11421ea9bea1183f657bdf'; // Replace with your actual client ID
const redirectUri = 'https://featurechain.app/callback'; // Replace with your redirect URI


function SpotifyCallback() {
  const [status, setStatus] = useState('Processing...');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      getToken(code);
    }
    }, []);

  
  const getToken = async (code) => {
    let codeVerifier = localStorage.getItem('code_verifier');

    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: clientId,
        grant_type: 'authorization_code',
        code,
        redirect_uri: redirectUri,
        code_verifier: codeVerifier,
      }),
    };
    const tokenUrl = 'https://accounts.spotify.com/api/token';
    try {
        const response = await fetch(tokenUrl, payload);
        const data = await response.json();
        
        if (data.access_token) {
          localStorage.setItem('access_token', data.access_token);
          createPlaylist(data.access_token);
        } else {
          setStatus('Error getting access token: ' + JSON.stringify(data));
        }
      } catch (error) {
        setStatus('Error getting token: ' + error.message);
      }
  };

  const createPlaylist = async (accessToken) => {
    const guesses = JSON.parse(localStorage.getItem('guesses'));

    try {
      // Get user ID
      const userResponse = await fetch('https://api.spotify.com/v1/me', {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      });
      const userData = await userResponse.json();
      let playlistText = localStorage.getItem('playlist_create_text');
      // Create playlist
      const playlistResponse = await fetch(`https://api.spotify.com/v1/users/${userData.id}/playlists`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: playlistText,
          description: 'Playlist created by FeatureChain www.featurechain.app',
          public: false
        })
      });
      const playlistData = await playlistResponse.json();

      // Add tracks to playlist
    //   const trackUris = guesses.map(guess => guess.song.uri);
    //    turn this list into a comma separated string
       const trackUris = guesses.map(guess => "spotify:track:"+guess.song.song_uri);
      await fetch(`https://api.spotify.com/v1/playlists/${playlistData.id}/tracks`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uris: trackUris
        })
      });

      setStatus('Playlist created successfully!');
      setTimeout(() => {
        window.location.href = 'https://open.spotify.com/playlist/' + playlistData.id;
      }, 300);
    } catch (error) {
      console.error('Error creating playlist:', error);
      setStatus('Error creating playlist');
    }
  };

  return <div>{status}
              <a href="/">Home</a>
              </div>;
}

export default SpotifyCallback;