import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';




const clientId = '1235a29f6d11421ea9bea1183f657bdf'; // Replace with your actual client ID
const redirectUri = 'https://featurechain.app/callback'; // Replace with your redirect URI

function CompletionPopup({ guesses, difficulty, onClose, onReset })  {
    const [timeUntilMidnight, setTimeUntilMidnight] = useState('');
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        const updateCountdown = () => {
        const now = new Date();
        const midnight = new Date(now);
        midnight.setHours(24, 0, 0, 0);
        const diff = midnight - now;

        const hours = Math.floor(diff / 3600000);
        const minutes = Math.floor((diff % 3600000) / 60000);
        const seconds = Math.floor((diff % 60000) / 1000);

        setTimeUntilMidnight(
            `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        );
        };

        updateCountdown(); // Initial call
        const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer); // Cleanup the timer
  }, []);
    const difficultytext = difficulty === 'daily' ? 'the daily challenge' : `the ${difficulty} difficulty challenge`;

    const generateRandomString = (length) => {
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const values = crypto.getRandomValues(new Uint8Array(length));
        return values.reduce((acc, x) => acc + possible[x % possible.length], "");
      }
      
      
    const sha256 = async (plain) => {
        const encoder = new TextEncoder()
        const data = encoder.encode(plain)
        return window.crypto.subtle.digest('SHA-256', data)
    }
    
    const base64encode = (input) => {
    return btoa(String.fromCharCode(...new Uint8Array(input)))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    }


    
    const exportAsPlaylist = async () => {

    const codeVerifier  = generateRandomString(64);
    const hashed =  await sha256(codeVerifier);
    const codeChallenge = base64encode(hashed);
    localStorage.setItem('code_verifier', codeVerifier);
    let today = new Date();
    today = format(today, 'd MMM yy');
    const playlistText = difficulty === 'daily' ? `FeatureChain Daily ${today} - ${guesses[0].oldName} to ${guesses[guesses.length - 1].newName}` : `FeatureChain ${difficulty} mode - ${guesses[0].oldName} to ${guesses[guesses.length - 1].newName}`;
    localStorage.setItem('playlist_create_text', playlistText);
    localStorage.setItem('guesses', JSON.stringify(guesses));

    const authUrl = new URL("https://accounts.spotify.com/authorize");
    const params = {
        client_id: clientId,
        response_type: 'code',
        redirect_uri: redirectUri,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge,
        scope: 'playlist-modify-private',
    };

    authUrl.search = new URLSearchParams(params).toString();
    window.location.href = authUrl.toString();

    };

    const handleOverlayClick = (e) => {
      if (e.target.className === 'popup-overlay') {
        onClose();
      }
    };

    const Toast = ({ message, duration = 2000, onClose }) => {
      console.log('Toast rendered');
      // useEffect(() => {
      //   const timer = setTimeout(() => {
      //     onClose();
      //   }, duration);
    
      //   return () => clearTimeout(timer);
      // }, [duration, onClose]);
    
      return (
        <div className="toast">
          {message}
        </div>
      );
    };

    const generateShareText = () => {
      let today = new Date();
      today = format(today, 'd MMM yy');
      if (difficulty === 'daily') {
        return `FeatureChain Daily ${today}: ${guesses[0].oldName} to ${guesses[guesses.length - 1].newName} in ${guesses.length} guesses!`;
      };
      return `FeatureChain ${difficulty} mode: ${guesses[0].oldName} to ${guesses[guesses.length - 1].newName} in ${guesses.length} guesses!`;
    };

    const handleShare = () => {
      // Replace this with your actual share text generation function
      
      const shareText = generateShareText();
      
      navigator.clipboard.writeText(shareText).then(() => {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 1500);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    };

    return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Congratulations!</h2>
        
        <p>You completed the {difficultytext} in {guesses.length} guesses.</p>
        {difficulty === "daily" && <p>New daily challenge in: {timeUntilMidnight}</p>}
        <div className="chain-visualization">
          {guesses.map((guess, index) => (
            <React.Fragment key={index}>
              <img src={guess.oldImage} alt={guess.oldName} className="artist-image" />
              <img src={guess.song.album_image} alt="Album cover" className="album-cover" />
            </React.Fragment>
          ))}
          <img src={guesses[guesses.length - 1].newImage} alt={guesses[guesses.length - 1].newName} className="artist-image" />
        </div>
        <div className="completion-button-container">
          <button onClick={exportAsPlaylist} className="action-button">
            <span className="button-icon">
              <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z" fill="#1ED760"/>
              </svg>
            </span>
            <span className="button-text">Export as playlist</span>
          </button>
          <button onClick={handleShare} className="action-button">
            <span className="button-icon">
            <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" fill="currentColor"/>
            </svg>
            </span>
            <span className="button-text">Share</span>
          </button>
          <button onClick={onReset} className="action-button">
            <span className="button-icon">
            <svg  viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="currentColor"/>
            </svg>
            </span>
            <span className="button-text">Home</span>
          </button>
          
        </div>
      </div>
        {showToast && (
        <Toast 
          message="Copied to clipboard!" 
          onClose={() => setShowToast(false)} 
        />
      )}
    </div>
  );
}

export default CompletionPopup;