import React, { useState, useEffect } from 'react';
import './App.css';
import LandingPage from './LandingPage';
import CompletionPopup from './CompletionPopup';  // We'll create this component
import HowToPlayPopup from './HowToPlayPopup';
import FAQPopup from './FAQpopup';

function App() {
  const [gameStarted, setGameStarted] = useState(false);
  const [difficulty, setDifficulty] = useState('');
  const [puzzle, setPuzzle] = useState(null);
  const [currentArtist, setCurrentArtist] = useState(null);
  const [startArtist, setStartArtist] = useState(null);
  const [goalArtist, setGoalArtist] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const [songs, setSongs] = useState([]);
  const [sortField, setSortField] = useState('feature_artist');
  const [sortDirection, setSortDirection] = useState(['asc', 'asc']);
  const [showCompletion, setShowCompletion] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);

  const serverUrl = "https://featurechain.app/api";
  // const serverUrl = "http://192.168.1.14:5000/api";

  useEffect(() => {
    // const savedDifficulty = localStorage.getItem('difficulty');
    // setDifficulty(savedDifficulty);
    // console.log('reading from local storage')
    // console.log(localStorage)
    if (difficulty === 'daily') {
      // setGameStarted(JSON.parse(localStorage.getItem('gameStarted') || 'false'));
      setPuzzle(JSON.parse(localStorage.getItem('puzzle') || 'null'));
      setCurrentArtist(JSON.parse(localStorage.getItem('currentArtist') || 'null'));
      setStartArtist(JSON.parse(localStorage.getItem('startArtist') || 'null'));
      setGoalArtist(JSON.parse(localStorage.getItem('goalArtist') || 'null'));
      setGuesses(JSON.parse(localStorage.getItem('guesses') || '[]'));
      setSongs(JSON.parse(localStorage.getItem('songs') || '[]'));
      setSortField(localStorage.getItem('sortField') || 'feature_artist');
      setSortDirection(JSON.parse(localStorage.getItem('sortDirection') || '["asc","asc"]'));
    }
  }, [gameStarted, difficulty]);

  useEffect(() => {
    // console.log('writing to local storage')
    // console.log(localStorage.getItem('guesses'))
    if (difficulty === 'daily') {
      // localStorage.setItem('gameStarted', JSON.stringify(gameStarted));
      localStorage.setItem('difficulty', difficulty);
      localStorage.setItem('puzzle', JSON.stringify(puzzle));
      localStorage.setItem('currentArtist', JSON.stringify(currentArtist));
      localStorage.setItem('startArtist', JSON.stringify(startArtist));
      localStorage.setItem('goalArtist', JSON.stringify(goalArtist));
      localStorage.setItem('guesses', JSON.stringify(guesses));
      localStorage.setItem('songs', JSON.stringify(songs));
      localStorage.setItem('sortField', sortField);
      localStorage.setItem('sortDirection', JSON.stringify(sortDirection));
    }
  }, [gameStarted, difficulty, puzzle, currentArtist, startArtist, goalArtist, guesses, songs, sortField, sortDirection]);


  useEffect(() => {
    if (gameStarted && !puzzle) {
      fetchPuzzle();
      console.log(puzzle)
      console.log(localStorage)
    }
  }, [gameStarted, difficulty]);

  useEffect(() => {
    if (currentArtist && goalArtist && currentArtist.uri === goalArtist.uri) {
      setShowCompletion(true);
    }
  }, [currentArtist, goalArtist]);

  // useEffect(() => {
  //   if (difficulty === 'daily' && guesses.length > 0) {
  //     console.log('resetting')
  //     const savedGuesses = JSON.parse(localStorage.getItem('guesses'));
  //     if (savedGuesses[0].oldUri !== startArtist.uri) {
  //       softResetGame();
  //     }
  //   }
  // }, [puzzle]);


  const renderHeader = () => {
    return (
      <header className="app-header">
        <button className="nav-button" onClick={goHome}>Home</button>
        <button className="nav-button" onClick={() => setShowHowToPlay(true)}>How to play</button>
        <button className="nav-button" onClick={() => setShowFAQ(true)}>FAQ</button>
        {(difficulty !== 'daily') && <button className="nav-button" onClick={softResetGame}>New game</button>}
      </header>
    );
  };

  const fetchPuzzle = async () => {
    try {
      const endpoint = getDifficultyEndpoint();
      const response = await fetch(`${serverUrl}/${endpoint}`);
      const data = await response.json();
      setPuzzle(data[0]);
      const [startImage, goalImage] = await Promise.all([
        getArtistImage(data[0].artist_1_uri),
        getArtistImage(data[0].artist_2_uri)
      ]);
      const savedStartArtist = JSON.parse(localStorage.getItem('startArtist'));
      const savedGoalArtist = JSON.parse(localStorage.getItem('goalArtist')); 
      const savedStartUri = savedStartArtist ? savedStartArtist.uri : null;
      const savedGoalUri = savedGoalArtist ? savedGoalArtist.uri : null;
      const fetchedStartUri = data[0].artist_1_uri;
      const fetchedGoalUri = data[0].artist_2_uri;

      if (savedStartUri && difficulty === 'daily' && (fetchedStartUri !== savedStartUri || fetchedGoalUri !== savedGoalUri)) {
        console.log("daily reset trigger")
        setGuesses([]);
        fetchSongs(data[0].artist_1_uri);
        setShowCompletion(false);
        localStorage.clear();
      } 
      // halt execution if saved states match so don't reset
      else if (savedStartUri && difficulty === 'daily' && fetchedStartUri === savedStartUri && fetchedGoalUri === savedGoalUri) {
        return 0;
      }
    
      setStartArtist({
        name: data[0].artist_1_name,
        uri: data[0].artist_1_uri,
        image: startImage
      });

      setCurrentArtist({
        name: data[0].artist_1_name,
        uri: data[0].artist_1_uri,
        image: startImage
      });
      setGoalArtist({
        name: data[0].artist_2_name,
        uri: data[0].artist_2_uri,
        image: goalImage
      });
    } catch (error) {
      console.error("Error fetching puzzle:", error);

    }
  };

  
  const fetchSongs = async (artistUri) => {
    try {
      const direction = sortField === 'feature_artist' ? sortDirection[1] : sortDirection[0];
      const response = await fetch(`${serverUrl}/songs/${sortField}/${direction}/${artistUri}`);
      const data = await response.json();
      setSongs(data);

    } catch (error) {
      console.error("Error fetching songs:", error);
    }
  };


  useEffect(() => {
    // console.log('initial fetch song trigger')
    if (puzzle && guesses.length === 0){
      fetchSongs(puzzle.artist_1_uri);
    }
  }, [puzzle]);
  
  const getDifficultyEndpoint = () => {
    switch (difficulty) {
      case 'daily':
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        return `puzzles/daily/${year}-${month}-${day}`;
      case 'normal':
        return 'puzzles/normal';
      case 'hard':
        return 'puzzles/hard';
      case 'expert':
        return 'puzzles/expert';
      default:
        return 'puzzles/easy';
    }
  };

  const startGame = (mode) => {
    setDifficulty(mode);
    setGameStarted(true);
    if (mode !== 'daily') {
      // Reset state for non-daily modes
      setPuzzle(null);
      setCurrentArtist(null);
      setStartArtist(null);
      setGoalArtist(null);
      setGuesses([]);
      setSongs([]);
      setSortField('feature_artist');
      setSortDirection(['asc', 'asc']);
      setShowCompletion(false);
    }
  };

  const resetGame = () => {
    setGameStarted(false);
    setDifficulty('');
    setPuzzle(null);
    setCurrentArtist(null);
    setStartArtist(null);
    setGoalArtist(null);
    setGuesses([]);
    setSongs([]);
    setSortField('feature_artist');
    setSortDirection(['asc', 'asc']);
    setShowCompletion(false);
  };

  const goHome = () => {
    
    if (difficulty === 'daily') {
      setGameStarted(false);
    }
    else {
      resetGame();

    }

  }
  const softResetGame = () => {
    setPuzzle(null);
    setGuesses([]);
    fetchPuzzle();
    startGame(difficulty);
  }

  if (!gameStarted) {
    return <LandingPage onStartGame={startGame} />;
  }
  
  const handleSort = (field) => {
    // console.log(field,sortDirection[0],sortDirection[1])
    setSortField(field);
    if (field === 'songname') {
      setSortDirection([sortDirection[0] === 'asc' ? 'desc' : 'asc', sortDirection[1]]);
    } else {
      setSortDirection([sortDirection[0] , sortDirection[1] === 'asc' ? 'desc' : 'asc']);
    };
    const sortedSongs = [...songs].sort((a, b) => {
        if (field === 'songname') {
          return sortDirection[0] === 'desc'
            ? a.songname.localeCompare(b.songname.toLowerCase())
            : b.songname.localeCompare(a.songname.toLowerCase());
        } else if (field === 'feature_artist') {
          return sortDirection[1] === 'desc'
            ? a.feature_artist.localeCompare(b.feature_artist.toLowerCase())
            : b.feature_artist.localeCompare(a.feature_artist.toLowerCase());
        }
        return 0;
      }
    )
    setSongs(sortedSongs)
    
  };



  const renderSortArrow = (field) => {
    if (sortField === field)  {
      return (field === 'feature_artist') ? sortDirection[1] === 'asc' ? ' ▲' : ' ▼' : sortDirection[0] === 'asc' ? ' ▲' : ' ▼';
    }
    return '';
  };





  const handleSongSelect = async (song,sortField,sortDirection) => {
    const newArtistImage = await Promise.resolve(getArtistImage(song.feature_uri));
    setGuesses([...guesses, {
      oldName: currentArtist.name,
      oldImage: currentArtist.image,
      newName: song.feature_artist,
      newImage: newArtistImage,
      song: song,
    }]);
    
    setCurrentArtist({
      name: song.feature_artist,
      uri: song.feature_uri,
      image: newArtistImage
    });
    fetchSongs(song.feature_uri,sortField,sortDirection);
    if (song.feature_uri === goalArtist.uri) {
      setShowCompletion(true);
    }
  };



  const getArtistImage = async (uri) => {
    try {
      const artistImage = await fetch(`${serverUrl}/artists/${uri}`);
      const data = await artistImage.json();
      return data.image;
    } catch (error) {
      console.error("Error fetching artist image:", error);
    }
  };

  if (!puzzle) return <div>Loading...</div>;

  return (
    <div className="App">

      {renderHeader()}
      {showHowToPlay && (
        <HowToPlayPopup onClose={() => setShowHowToPlay(false)} />
      )}
      {showFAQ && (
        <FAQPopup onClose={() => setShowFAQ(false)} />
      )}
      <div className="artist-portraits">
        <div className="portrait">
          <h3>START</h3>
          <img src={startArtist ? startArtist.image: ""} alt="Start Artist" />
          <h3>{startArtist ? startArtist.name: ""}</h3>
        </div>
        <div className="portrait">
          <h3>CURRENT</h3>
          <img src={currentArtist ? currentArtist.image: ''} alt="Current Artist" />
          <h3>{currentArtist ? currentArtist.name : ''}</h3>
        </div>
        <div className="portrait">
          <h3>GOAL</h3>
          <img src={goalArtist ? goalArtist.image : ""} alt="Goal Artist" />
          <h3>{goalArtist ? goalArtist.name: ""}</h3>
        </div>
      </div>
      <div className="guesses-container">
        <h3>Guesses: {guesses.length}</h3>
      </div>
      {showCompletion && (
        <CompletionPopup
          guesses={guesses}
          difficulty={difficulty}
          onClose={() => setShowCompletion(false)}
          onReset={difficulty === 'daily' ? goHome : softResetGame}
        />
      )}

      <div className="scrollable-container">
        {guesses.map((guess, index) => (
          <div key={index} className="guess-row">
            <div className="artist-box old-artist">
              <img src={guess.oldImage} alt={guess.oldName} className="artist-image" />
              <p>{guess.oldName}</p>
            </div>
            <div className="connector">
              <div className="dotted-line"></div>
            </div>
            <div className="song-info">
              <img src={guess.song.album_image} alt="Album cover" className="album-cover" />
              <p>{guess.song.songname}</p>
            </div>
            <div className="connector">
              <div className="dotted-line"></div>
            </div>
            <div className="artist-box new-artist">
              <img src={guess.newImage} alt={guess.newName} className="artist-image" />
              <p>{guess.newName}</p>
            </div>
          </div>
        ))}
      </div>
      <h3>Pick a {currentArtist && currentArtist.name} song:</h3>
      <div className="song-list">
        <div className="song-list-header">
          <span className="album-col"></span>
          <span className="song-col" onClick={() => handleSort('songname',true)}>
          Song name{renderSortArrow('songname')}
          </span>
          <span className="artist-col" onClick={() => handleSort('feature_artist',true)}>
          Featured artist{renderSortArrow('feature_artist')}
        </span>
        </div>
        <div className="song-list-body">
          {songs.map((song, index) => (
            <div key={index} className={`song-item ${index % 2 === 0 ? 'even' : 'odd'}`} onClick={() => handleSongSelect(song)}>
              <span className="album-col">
                <img src={song.album_image} alt="Album cover" className="album-cover" />
              </span>
              <span className="song-col">{song.songname}</span>
              <span className="artist-col">{song.feature_artist}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;