import React from 'react';

function HowToPlayPopup({ onClose }) {
  const handleOverlayClick = (e) => {
    if (e.target.className === 'popup-overlay') {
      onClose();
    }
  };
  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
      <button className="close-button" onClick={onClose}>&times;</button>
        <h2>How to Play</h2>
        <p>Your goal is to connect 2 artists through a series of features.</p>
        <p>Use the song selection interface at the bottom of the page to get from the Start to the Goal Artist.</p>
        <p>When you pick a song, that song's feature artist becomes the next link in the chain.</p>
        <p>For example:</p>
        <ul>
          <li>Start Artist: Drake</li>
          <li>Take Care - Drake ft. Rihanna</li>
          <li>Hate that I love you - Rihanna ft. Ne-Yo</li>
          <li>She is - Ne-Yo ft. Tim McGraw</li>
          <li>Goal Artist: Tim McGraw</li>
        </ul>
        <p>Is a 3-song chain from Drake to Tim McGraw.</p>

        <h3>Game modes</h3>
        <p>There are currently four game modes - one daily challenge and three freeplay modes.</p>
        <p>The daily challenge is the same for everyone, resets at midnight your local time, and can always be solved in 2 guesses. The two artists are always taken from the global top 100 (by Spotify listenership)</p>
        <p>Normal freeplay has the same difficulty settings as the daily challenge.</p>
        <p>Hard mode: the artists are selected from the top 250, and the optimal path is 3 songs.</p>
        <p>Expert mode: top 1000 artists, 4 song optimal path.</p>


      </div>
    </div>
  );
}

export default HowToPlayPopup;