import React from 'react';

function FAQPopup({ onClose }) {
  const handleOverlayClick = (e) => {
    if (e.target.className === 'popup-overlay') {
      onClose();
    }
  };
  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
      <button className="close-button" onClick={onClose}>&times;</button>
        <h2>FAQ</h2>
        <h3>Why is *insert song* not available for me to pick?</h3>
        <p>Songs are only included if they have a featured artist listed, and some songs don't have their features correctly listed on Spotify. Also, both the main artist and the feature must be in the top 2500 worldwide to be included.</p>
        <h3>This is really cool and I want to support you, how can I do that?</h3>
        <a href='https://ko-fi.com/B0B310DMJ6' target='_blank'><img height='36' src='https://storage.ko-fi.com/cdn/kofi2.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
        <p>Otherwise, just playing and sharing the game with your friends is cool. If anyone can get Fantano to play this I'd be ecstatic.</p>
        <h3>Why is *insert feature* not working?</h3>
        <p>I have literally never written a single line of react in my life before this. If something's really broken (or you have a cool suggestion for something to add), email me at crabbix.crabbix@gmail.com</p>
        <h3>Will you make a version with movies and actors?</h3>
        <p>As soon as someone gives me <a href="https://aws.amazon.com/marketplace/search/results?FULFILLMENT_OPTION_TYPE=DATA_EXCHANGE&CREATOR=0af153a3-339f-48c2-8b42-3b9fa26d3367&DATA_AVAILABLE_THROUGH=API_GATEWAY_APIS&filters=FULFILLMENT_OPTION_TYPE%2CCREATOR%2CDATA_AVAILABLE_THROUGH">$150,000</a> for the IMDb API</p>
        <h3>Can't I just cheat by googling an artist's songs?</h3>
        <p>Yeah man</p>
      </div>
    </div>
  );
}

export default FAQPopup;